<template>
  <div>
    <div class="tables-container" v-show="!xqisShow">
      <el-row>
        <el-col
          :span="24"
          style="border: 1px solid #bbb; overflow: hidden; height: 43vh"
        >
          <div class="xzl">
            <div class="xzl-xzmb">待办事项列表</div>

            <div class="xzl-div-btn">
              <el-form ref="form" :model="queryform" label-width="190px">
                <el-form-item label="事项类型：" class="now">
          <el-select
            v-model="queryform.affairType"
            placeholder="状态"
            class="cxl-input"
          >
            <el-option label="申报" value="1"></el-option>
            <el-option label="理赔" value="2"></el-option>
            <el-option label="预存" value="3"></el-option>
          </el-select>
        </el-form-item>
              </el-form>
              <el-button class="xzl-div-btns" @click="ck(0)">
                <i style="font-size: 16px" class="el-icon-zoom-in"></i>查看
              </el-button>
            </div>
          </div>
          <el-table
            height="300px"
            border
            :data="pendingTasks"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="ID"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="username"
              label="用户名"
            ></el-table-column>
            <el-table-column prop="operationTime" label="日期"></el-table-column>
            <el-table-column prop="affairType"  label="事项类型">  
            </el-table-column>
            <el-table-column prop="insType" label="事项名称"></el-table-column>
            <el-table-column prop="operationDesc" label="描述"></el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              label="操作"
              width="150"
              style="display: flex; justify-content: left"
            >
              <template slot-scope="scope">
                <i
                  class="el-icon-edit"
                  title="详情"
                  @click="handleEdit(scope.$index, scope.row,0)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <div
            v-if="pendingTasks.length"
            style="text-align: left; margin-top: 5px"
          >
            <el-pagination
              @size-change="handleSizeChangeOne"
              @current-change="handleCurrentChangeOne"
              :current-page="currentPageOne"
              :page-sizes="pageSizesOne"
              :page-size="PageSizeOne"
              layout="total, sizes, prev, pager, next, jumper"
              :total="allpendingTasks.length"
            >
            </el-pagination>
          </div>
        </el-col>
        <el-col
          :span="24"
          style="
            border: 1px solid #bbb;
            overflow: hidden;
            height: 43vh;
            margin-top: 2vh;
          "
        >
          <div class="xzl">
            <div class="xzl-xzmb">已办事项列表</div>
          </div>
    
          <el-table
            height="300px"
            border
            :data="completedTasks"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="ID"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="username"
              label="用户名"
              width="180"
            ></el-table-column>
            <el-table-column prop="operationTime" label="日期"></el-table-column>
            <el-table-column  prop="affairType"  label="事项类型">
            
            </el-table-column>
            <el-table-column prop="insType" label="事项名称"></el-table-column>
            <el-table-column prop="status" label="描述"></el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              label="操作"
              width="150"
              style="display: flex; justify-content: left"
            >
              <template slot-scope="scope">
                <i
                  class="el-icon-edit"
                  title="详情"
                  @click="handleEdit(scope.$index, scope.row,1)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <div
            v-if="completedTasks.length"
            style="text-align: left; margin-top: 5px"
          >
            <el-pagination
              @size-change="handleSizeChangeTwo"
              @current-change="handleCurrentChangeTwo"
              :current-page="currentPageTwo"
              :page-sizes="pageSizesTwo"
              :page-size="PageSizeTwo"
              layout="total, sizes, prev, pager, next, jumper"
              :total="allcompletedTasks.length"
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-show="xqisShow">
      <h4>申报表单详情</h4>
      <el-table :data="xqtableData" border style="width: 100%"  @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
        v-for="(item, index) in xqtableTop"
        align="center"
        header-align="center"
        :key="index"
        :label="item.fieldName"
        :prop="item.field"
      >
    </el-table-column>
      </el-table>
      <div style="margin-top: 5px; display: flex; justify-content: left" >
        <el-form ref="form"  :model="spform" label-width="190px">
          <el-form-item class="now" label="审批意见：" v-if="nowlxBoolean">
             <el-select
            v-model="spform.status"
            placeholder="审批意见"
            class="cxl-input"
          >
            <el-option label="审批通过" value="4"></el-option>
            <el-option label="审批不通过" value="5"></el-option>
          </el-select>
          </el-form-item>
        
        </el-form>
        <el-button
          class="table-bottom-btns"
          style="background-color: rgb(231, 102, 109)"
          type="danger"
         @click="handleModify()"
         v-if="nowlxBoolean"
        >
          <i style="font-size: 15px" class="el-icon-circle-check" ></i
          >确定提交</el-button
        >
        <el-button class="table-bottom-btns" @click="toggleSelection()">
          <i style="font-size: 15px" class="el-icon-circle-close"></i
          >取消审核</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getOaData,getOaSpData,tableFrom } from "@/axios/oa.js";
import { getDataTop } from "@/axios/tableTop.js";
export default {
  data() {
    return {
      // 默认显示第几页
      currentPageOne: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCountOne: 1,
      // 个数选择器（可修改）
      pageSizesOne: [50],
      // 默认每页显示的条数（可修改）
      PageSizeOne: 50,
      // 默认显示第几页
      currentPageTwo: 1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCountTwo: 1,
      // 个数选择器（可修改）
      pageSizesTwo: [50],
      // 默认每页显示的条数（可修改）
      PageSizeTwo: 50,
      // table查询项
      queryform:{
  current: 1,
  size: 50,
  affairType: "1",
  roleId:JSON.parse(localStorage.getItem("roleId")),
  username:JSON.parse(localStorage.getItem("username")),
},
      username:JSON.parse(localStorage.getItem("username")),
      multipleSelection: [], // 用于存储选中项
      pendingTasks: [
      
      ],
      allpendingTasks: [], // 用于存储所有的用户数据
      completedTasks: [
       
      ],
      allcompletedTasks: [], // 用于存储所有的用户数据
      // 待办事项查询
      dbform: {
        name: "",
      },
      // 审批参数
      spform: {
        username: "",
        affairType:'',
        insType:'',
        records:[],
        status:'',
        operationTime:'',
        notSelectedRecords:[],
        operationDesc:''
      },
      // 展示详情页面
      xqisShow: false, //false不展示详情页 true 展示详情
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        }, {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        }, {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        }, {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      xqtableTop:[],
      xqtableData:[],
      queryXqform:{
        username:'',
        affairType:'',
        insType:'',
        operationTime:'',
        current:'1',
        size:'50'
      },
      // 业务type
      pageType:'',
      // 判断详情点击的是代办/已办
      nowlxBoolean:true,
    };
  },
  methods: {

        // 选中table 复选框数据
        handleSelectionChange(val) {
      this.spform.records = val;
      this.spform.records = this.spform.records.map(item => item.id);
      console.log(this.spform.records);
      this.spform.notSelectedRecords = this.xqtableData
  .filter(item => !this.spform.records.includes(item.id))
  .map(item => item.id);
      
    },

    // 查看功能
    ck() {
      let e=this.queryform
      getOaData(e).then(res=>{
        if (res.code == 200) {
          if (res.data) {
            this.allpendingTasks = res.data.notAcceptFormDataList;
          this.allcompletedTasks = res.data.acceptFormDataList;
          this.getpendingTasks();
          this.getcompletedTasks()
          }
        }
      })
    },
    getpendingTasks() {
    const start = (this.currentPageOne - 1) * this.PageSizeOne;
    const end = start + this.PageSizeOne;
    this.pendingTasks = this.allpendingTasks.slice(start, end); // 根据页码和每页显示数量截取数据
},
getcompletedTasks() {
    const start = (this.currentPageTwo - 1) * this.PageSizeTwo;
    const end = start + this.PageSizeTwo;
    this.completedTasks = this.allcompletedTasks.slice(start, end); // 根据页码和每页显示数量截取数据
},
handleSizeChangeOne(val) {
      this.PageSizeOne = val;
      this.getpendingTasks();
    },
    handleCurrentChangeOne(val) {
      this.currentPageOne = val;
      this.getpendingTasks();
    },
    handleSizeChangeTwo(val) {
      this.PageSizeTwo = val;
      this.getcompletedTasks()
    },
    handleCurrentChangeTwo(val) {
      this.currentPageTwo = val;
      this.getcompletedTasks()
    },
    // 详情
    handleEdit(index, row,nowlx) {
      this.spform.operationDesc=row.operationDesc;
      if(nowlx==0){
        this.nowlxBoolean=true;
      }
      if(nowlx==1){
        this.nowlxBoolean=false;
      }
      this.spform.username=row.username;
      this.spform.operationTime=row.operationTime;
      //  切换详情页面
      this.xqisShow = true;
      this.queryXqform.username=row.username
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
    });
      if (row.affairType=='申报') {
        this.queryXqform.affairType='1'
      }else if (row.affairType=='理赔') {
        this.queryXqform.affairType='2'
      }else if (row.affairType=='预存') {
        this.queryXqform.affairType='3'
      }
      if (row.insType=='商业险') {
        this.queryXqform.insType='01'
      }else if(row.insType=='车辆雇主责任险') {
        this.queryXqform.insType='02'
      }else if(row.insType=='交强险') {
        this.queryXqform.insType='03'
      }else if(row.insType=='货运险') {
        this.queryXqform.insType='04'
      }else if(row.insType=='新车货运险') {
        this.queryXqform.insType='13'
      }else if(row.insType=='二手车货运险') {
        this.queryXqform.insType='14'
      }else if(row.insType=='财产险') {
        this.queryXqform.insType='05'
      }else if(row.insType=='责意险') {
        this.queryXqform.insType='06'
      }else if(row.insType=='货运三者险') {
        this.queryXqform.insType='07'
      }else if(row.insType=='商品车运输险') {
        this.queryXqform.insType='08'
      }

      if (row.affairType=='申报') {
        this.pageType='0'
      }if (row.affairType=='理赔') {
        this.pageType='1'
      }
      if (row.affairType=='预存') {
        this.pageType='2'
      }

      getDataTop({pageType:this.pageType,insType:this.queryXqform.insType})
      .then((res) => {
        this.xqtableTop = res.data;
        loading.close();
      });
      this.queryXqform.operationTime=row.operationTime
      this.queryXqform.records=row.records
      let e =this.queryXqform
      tableFrom(e)
      .then(res=>{
        if (res.code == 200) {
          this.xqtableData=res.data
          loading.close();
        }
      })
    },
    // 确定提交
    handleModify(){
      this.spform.insType=this.queryXqform.insType;
      this.spform.affairType=this.queryXqform.affairType;
      console.log(this.spform,'提交数据');
      
      // this.multipleSelection  勾选数据
      if (this.spform.records.length==0) {
        this.$message({
            message: "请勾选至少一条申报数据",
            type: "error",
          });
      }else{
        if (this.spform.status=='') {
          this.$message({
            message: "请选择审批意见",
            type: "error",
          });
        }else{
          getOaSpData(this.spform)
      .then(res=>{
        if (res.code == 200) {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.toggleSelection();
          this.ck();
        }
      })
        }
      
      }
    

    },
    // 退出详情
    toggleSelection() {
      this.xqisShow = false;
      this.spform.status=''
    },
  },
  mounted() {
    this.ck();
  },
};
</script>

<style lang="less" scoped>
/deep/.now {
  margin-bottom: 2px !important;
}
// 下载栏
.xzl {
  border: 1px, solid, rgb(221, 221, 221);
  display: flex;
  align-items: center; /* 竖向居中 */
  // 下载模版
  .xzl-xzmb {
    font-size: 24px;
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    // color: rgba(30, 159, 255);
    font-weight: 900;
    cursor: pointer;
    margin-right: 15px;
  }

  // 上传+导入
  .xzl-div-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;

    // 按钮公共样式
    .xzl-div-btns {
      height: 28px;
      line-height: 28px;
      padding: 0 10px;
      font-size: 12px;
      color: white;
      font-weight: 900;
      background-color: rgba(0, 150, 136);
    }

    .xzl-div-btns:hover {
      opacity: 0.8;
    }
  }
}
.table-bottom-btns {
  margin-top: 6px;
  margin-left: 5px;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  font-size: 12px;
  color: white;
  font-weight: 900;
  background-color: rgba(0, 150, 136);
}

.table-bottom-btns:hover {
  opacity: 0.8;
  /* 举例使用 Element UI 的主题色作为文字颜色 */
}
</style>
